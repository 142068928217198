<template>
  <v-text-field
    :id="id"
    :ref="inputRef"
    :label="label"
    :value="value"
    :loading="loading"
    :disabled="disabled"
    :readonly="readonly"
    :hide-details="hideDetails"
    :prefix="prefix"
    :suffix="suffix"
    outlined
    :rules="rules"
    :type="type"
    class="pt-0"
    :class="{ ...customClass, 'mt-3': !hideTopMargin }"
    v-model.trim="textinput"
    :append-outer-icon="appendOuterIcon"
    :placeholder="placeholder"
    v-on:click:append-outer="$emit('click:append-outer', true)"
    v-on:onchange="$emit('onchange', true)"
    v-on:keyup="$emit('keyup', true)"
    v-bind="vBindAttrs"
    v-on="vOn"
    :counter="counterCount"
    v-on:keypress="(e) => manageLimitdescr(e)"
    v-on:paste="(e) => onPaste(e)"
  ></v-text-field>
</template>
<script>
export default {
  name: "text-input",
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    rules: {
      type: [Array, Object],
      default: () => {
        return [];
      },
    },
    placeholder: {
      type: String,
      default: null,
    },
    inputRef: {
      type: String,
      default: "textInput",
    },
    id: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    vBindAttrs: {
      type: Object,
      default: () => {
        return {};
      },
    },
    vOn: {
      type: Object,
      default: () => {
        return {};
      },
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    appendOuterIcon: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "text",
    },
    prefix: {
      type: String,
      default: null,
    },
    suffix: {
      type: String,
      default: null,
    },
    // className: {
    //   type: String,
    //   default: "",
    // },
    customClass: {
      type: String,
      default: "",
    },
    hideTopMargin: {
      type: Boolean,
      default: false,
    },
    counterCount: {
      type: Number,
      default: 50,
    },
  },
  data() {
    return {
      textinput: null,
    };
  },
  methods: {
    onPaste(e) {
      let coppied = e.clipboardData.getData("Text");
      let preVal = this.textinput;
      let finalVal = String(preVal) + String(coppied);
      if (Number(finalVal.length) > this.counterCount - 1) {
        let trimValue = finalVal.substring(0, this.counterCount);
        this.textinput = trimValue;
        e.preventDefault();
      }
    },
    changeText() {
      let finalVal = this.textinput;
      if (Number(finalVal.length) > this.counterCount - 1) {
        let trimValue = finalVal.substring(0, this.counterCount);
        this.textinput = trimValue;
      }
    },
    manageLimitdescr(e) {
      if (this.textinput && this.textinput.length > this.counterCount - 1) {
        e.preventDefault();
      }
    },
  },
  watch: {
    value() {
      this.textinput = this.value;
    },
    textinput() {
      this.changeText();
      this.$emit("input", this.textinput);
    },
  },
  mounted() {
    this.textinput = this.value;
  },
};
</script>
<style scoped>
.v-application .v-btn.v-size--default {
  min-width: 20px;
}
.v-input {
  border: 1px solid #bec1c7;
  border-radius: 0px;
}
</style>
