<template>
  <div>
    <v-layout class="p-4 border-bottom-light-grey min-height-57px">
      <v-flex class="font-level-3-bold my-auto">
        <h1 class="form-title margin-auto">
          <template v-if="typeText">
            {{ typeText }}
          </template>
          <template v-else> Files </template>
        </h1>
      </v-flex>
    </v-layout>
    <div class="overflow-y" style="max-height: calc(100vh - 345px)">
      <v-row>
        <template v-if="dbFiles.length">
          <v-col
            md="12"
            v-for="(row, index) in dbFiles"
            :key="index"
            class="border-bottom d-flex"
            :class="{
              'custom-border-top': index == 0,
              'orange_lighten-5': index % 2 == 0,
            }"
          >
            <div class="px-4" style="width: 60px">
              <template
                v-if="row.file.extension && isImage(row.file.extension)"
              >
                <ImageTemplate
                  :src="row.file.url"
                  style="max-width: 50px; width: 50px"
                ></ImageTemplate>
              </template>
              <template v-else>
                <inline-svg
                  style="max-width: 50px; width: 50px"
                  :src="$assetURL(`media/mime/${row.file.extension}.svg`)"
                />
              </template>
            </div>
            <div style="width: 40%" class="px-4 text-truncate">
              <b class="blue--text text--darken-4">{{ row.name }}</b>
              <div><b>Size : </b>{{ (row.file.size / 1024).toFixed(2) }}KB</div>
            </div>
            <div style="width: 40%" class="px-4 d-flex flex-wrap">
              <div class="mr-10">
                <div>
                  <b class="d-inline-block" style="width: 40px">Start </b
                  >:&nbsp;&nbsp;
                  <span v-if="row.start_date">
                    {{ row.start_date }}
                  </span>
                  <em class="text-muted" v-else> no start date </em>
                </div>
                <div>
                  <b class="d-inline-block" style="width: 40px">End </b
                  >:&nbsp;&nbsp;
                  <span v-if="row.end_date">
                    {{ row.end_date }}
                  </span>
                  <em class="text-muted" v-else> no end date </em>
                </div>
              </div>
              <div class="mr-10">
                <b class="d-inline-block" style="width: 64px">Reminder </b
                >:&nbsp;&nbsp;
                <span v-if="row.reminder_date">
                  {{ row.reminder_date }}
                </span>
                <em class="text-muted" v-else> no reminder date </em>
              </div>
            </div>
            <div style="width: 10%" class="px-4 d-flex">
              <v-spacer></v-spacer>
              <v-btn
                v-on:click="doAction(row, 'download')"
                small
                icon
                depressed
                color="blue darken-4"
                class="mr-2"
              >
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </div>
          </v-col>
        </template>
        <template v-else>
          <v-col class="text-center">
            <p class="m-0 row-not-found text-center">
              <img
                :src="$assetURL('media/error/empty.png')"
                class="row-not-found-image"
              />
              Uhh... There are no file at the moment.
            </p>
          </v-col>
        </template>
      </v-row>
      <table width="100%" class="" v-if="false">
        <thead>
          <tr class="custom-border">
            <th class="text-left border-right-new" width="50"></th>
            <th class="text-left border-right-new">NAME</th>
            <th class="text-left border-right-new">RELATED TO</th>
            <th class="text-left border-right-new">ADDED AT</th>
            <!-- <th class="text-left border-right-new">File Size</th> -->
            <th class="text-left border-right-new"></th>
          </tr>
        </thead>
        <tbody v-if="dbFiles.length">
          <tr
            v-for="(row, index) in dbFiles"
            :key="index"
            class="line-item-listing-row"
            :class="{ 'custom-border-top': index > 0 }"
          >
            <td class="text-left border-right-new" width="200">
              <template
                v-if="row.file.extension && isImage(row.file.extension)"
              >
                <ImageTemplate
                  :src="row.file.url"
                  style="max-width: 50px; width: 50px"
                ></ImageTemplate>
              </template>
              <template v-else>
                <inline-svg
                  style="max-width: 50px; width: 50px"
                  :src="$assetURL(`media/mime/${row.extension}.svg`)"
                />
              </template>
            </td>
            <td class="text-left border-right-new">
              {{ row.name }}
            </td>
            <td class="text-left border-right-new">
              <v-chip
                :color="row.type == 301 ? 'red' : 'green'"
                label
                class="text-white"
                outlined
                style="height: 25px"
              >
                {{ getType(row.type) }}
              </v-chip>
            </td>
            <td class="text-left border-right-new">
              <v-chip
                color="cyan"
                label
                class="text-white"
                outlined
                style="height: 25px"
              >
                {{ formatDate(row.added_at) }}
              </v-chip>
            </td>

            <td class="text-left border-right-new">
              <v-btn
                v-on:click="doAction(row, 'download')"
                small
                icon
                depressed
                color="blue darken-4"
                class="mr-2"
                ><v-icon>mdi-download</v-icon></v-btn
              >
              <!-- <v-btn small v-on:click="doAction(row, 'delete')" icon depressed color="red lighten-1"
								  ><v-icon >mdi-delete</v-icon></v-btn
							  > -->
            </td>
          </tr>
        </tbody>
        <tfoot v-else>
          <tr>
            <td colspan="6">
              <p class="m-0 row-not-found text-center">
                <img
                  :src="$assetURL('media/error/empty.png')"
                  class="row-not-found-image"
                />
                Uhh... There are no file at the moment.
              </p>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
    <Dialog :dialog="deleteDialog" :dialog-width="600">
      <template v-slot:title> Delete File</template>
      <template v-slot:body>
        <v-row class="delete-dialog">
          <v-col md="2" class="py-0 text-right my-auto">
            <span class="svg-icon svg-icon-lg delete-confirm-icon">
              <!--begin::Svg Icon-->
              <inline-svg
                :src="$assetURL('media/custom-svg/attention-circle.svg')"
              />
              <!--end::Svg Icon-->
            </span>
          </v-col>
          <v-col md="10" class="py-0 my-auto">
            <p class="btx-p m-0">
              Deleting File is irreversible, Are you sure about deleting it?
            </p>
          </v-col>
        </v-row>
      </template>
      <template v-slot:action>
        <v-btn
          class="white--text"
          :loading="deleteLoading"
          :disabled="deleteLoading"
          depressed
          color="red lighten-1"
          tile
          v-on:click="deleteFile()"
        >
          Yes! Delete
        </v-btn>
        <v-btn
          depressed
          tile
          :disabled="deleteLoading"
          v-on:click="deleteDialog = false"
        >
          No, Close
        </v-btn>
      </template>
    </Dialog>
  </div>
</template>
<script>
import { toNumber, round, last, find } from "lodash";
import Dialog from "@/view/components/Dialog";
import ApiService from "@/core/services/api.service";
import { SET_MESSAGE } from "@/core/services/store/common.module";
import ImageTemplate from "@/view/pages/Image";
import CommonMixin from "@/core/plugins/common-mixin";

export default {
  mixins: [CommonMixin],
  props: {
    typeText: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
    url: {
      type: String,
      default: null,
    },
    typeId: {
      type: Number,
      default: 0,
    },
    documents: {
      type: Array,
      default: () => {
        return new Array();
      },
    },
  },
  watch: {
    documents: {
      handler(param) {
        if (param) {
          this.dbFiles = param;
        }
      },
    },
  },
  data() {
    return {
      pageLoading: true,
      deleteLoading: false,
      deleteDialog: false,
      attachDialog: false,
      attachLoading: false,
      id: 0,
      extensionArray: ["jpg", "png", "gif", "jpeg", "webp", "tiff", "jfif"],
      description: null,
      dbFiles: [],
    };
  },
  methods: {
    getType(type) {
      if (type == 301) {
        return "Visible in PDF";
      } else if (type == 302) {
        return "Internal use only";
      }
    },
    isImage(ext) {
      if (find(this.extensionArray, (row) => row == ext.toLowerCase())) {
        return true;
      } else {
        return false;
      }
    },
    init() {
      this.deleteLoading = false;
      this.deleteDialog = false;
      this.attachDialog = false;
      this.attachLoading = false;
      this.id = null;
      this.description = null;
    },
    getFileExtension(path) {
      if (path) {
        return last(path.split("."));
      }
    },
    getFileSize(size) {
      return round(toNumber(size) / 1024, 2);
    },

    doAction(row, param) {
      switch (param) {
        case "download":
          window.open(row.file.url, "_blank");
          break;
        case "delete":
          this.id = row.id;
          this.deleteDialog = true;
          break;
      }
    },
    deleteFile() {
      if (!this.id) {
        return false;
      }
      this.deleteLoading = true;
      ApiService.delete(
        `${this.url}/${this.typeId}/${this.type}/attachment/${this.id}`
      )
        .then(() => {
          this.deleteLoading = false;
          this.$store.commit(SET_MESSAGE, [
            { model: true, message: "Success ! File Deleted successfully." },
            this.$emit("success", true),
          ]);
          this.getFiles();
        })
        .catch((error) => {
          this.$emit("error", error);
          this.logError(error);
        })
        .finally(() => {});
    },
    getFiles() {
      ApiService.get(`${this.url}/${this.typeId}/${this.type}/attachment`)
        .then(({ data }) => {
          this.dbFiles = data;
          this.init();
        })
        .catch((error) => {
          this.$emit("error", error);
          this.logError(error);
        })
        .finally(() => {
          this.pageLoading = false;
        });
    },
  },
  components: {
    Dialog,
    ImageTemplate,
  },
  mounted() {
    this.dbFiles = this.documents;
  },
};
</script>
