<template>
  <Dialog :common-dialog="pdfDialog" :dialog-width="dialogWidth">
    <template v-slot:title>{{ pdfTitle }}</template>
    <template v-slot:body>
      <v-container v-if="pdfUrl" class="grey">
        <div v-for="page in pageCount" :key="page" class="my-10 mx-10">
          <PDFViewer
            :page="page"
            src="https://bthrust-fsm.s3.ap-southeast-1.amazonaws.com/SL012021VF/files/application/pdf/Xfx2JH7w8mNhJ8g97J7x9KId0GZc4EvQ3wDI91bk.pdf"
          />
        </div>
      </v-container>
    </template>
    <template v-slot:action>
      <v-btn
        v-on:click="$emit('close')"
        class="mx-2 custom-grey-border custom-bold-button"
      >
        Close
      </v-btn>
    </template>
  </Dialog>
</template>

<script>
import Dialog from "@/view/pages/partials/Dialog";
import PDFViewer from "vue-pdf";

export default {
  props: {
    pdfDialog: {
      type: Boolean,
      default: false,
    },
    pdfTitle: {
      type: String,
      default: null,
    },
    pdfUrl: {
      type: String,
      default: null,
    },
  },
  watch: {
    pdfDialog(param) {
      if (param) {
        this.init();
      } else {
        this.currentPage = 0;
        this.pageCount = 0;
      }
    },
  },
  data() {
    return {
      currentPage: 0,
      pageCount: 0,
    };
  },
  methods: {
    init() {
      let _this = this;
      let pdfObject = PDFViewer.createLoadingTask(
        "https://bthrust-fsm.s3.ap-southeast-1.amazonaws.com/SL012021VF/files/application/pdf/Xfx2JH7w8mNhJ8g97J7x9KId0GZc4EvQ3wDI91bk.pdf"
      );
      pdfObject.promise.then((pdf) => {
        _this.pageCount = pdf.numPages;
      });
    },
  },
  components: {
    Dialog,
    PDFViewer,
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 60);
    },
  },
};
</script>
