<template>
  <v-row>
    <v-col cols="12" md="12" v-if="Array.isArray(files) && files.length">
      <div
        class="d-flex align-center py-1"
        v-for="(file, index) in files"
        :key="'file-' + index"
      >
        <div style="max-width: 50px; min-width: 50px; width: 50px">
          <inline-svg
            style="max-width: 40px; width: 40px"
            :src="$assetURL(`media/mime/${file.suffix}.svg`)"
          />
        </div>
        <TextField
          :id="`document-name-${index}`"
          :class="{ required: required && !file.name }"
          :rules="rules(file.name, `Name ${index + 1}`)"
          v-model.trim="file.name"
          hide-details
          dense
          filled
          solo
          flat
          :counter="50"
          placeholder="File Name"
          :suffix="`${file.suffix ? file.suffix : ''}`"
          style="width: 100%"
          @keypress="emitFile"
        ></TextField>
        <div style="max-width: 40px; min-width: 40px; width: 40px">
          <v-btn
            v-on:click="removeFile(index)"
            color="red lighten-1 white--text"
            icon
          >
            <v-icon>mdi-delete-outline</v-icon>
          </v-btn>
        </div>
      </div>
    </v-col>
    <v-col cols="12" md="12" class="py-0" v-if="files.length < 1">
      <div
        class="py-5 upload-area text-center text-secondary"
        :class="{ required: required && !files.length }"
        @click="handleClick"
      >
        <v-icon size="60">mdi-cloud-upload</v-icon>
        <div class="text-h5 text-secondary">Click here to select file</div>
        <p class="mt-1 text-grey-700">
          Allowed ( {{ getAllowedFiles }} ). <br />
        </p>
      </div>
      <input
        ref="refInputEl"
        type="file"
        name="file"
        :accept="getAcceptFile()"
        hidden
        @change="uploadFile"
      />
    </v-col>
  </v-row>
</template>

<script>
import { ErrorEventBus } from "@/core/lib/message.lib";
import { cloneDeep, isArray, lowerCase } from "lodash";
import ValidationMixin from "@/core/plugins/validation-mixin";
export default {
  name: "FileInput",
  mixins: [ValidationMixin],
  data() {
    return {
      attachLoading: false,
      refInputEl: null,
      activeClass: "",
      files: [],
      timeout: null,
    };
  },
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: [Array],
      default: () => {
        return [];
      },
    },
    maxLimit: {
      type: Number,
      default: 5,
    },
    maxSize: {
      type: Number,
      default: 5,
    },
    required: {
      type: Boolean,
      default: false,
    },
    totalFiles: {
      type: Number,
      default: 0,
    },
    validFileTypes: {
      type: Array,
      default: () => {
        return [
          "jpeg",
          "jpg",
          "png",
          "gif",
          "pdf",
          "docx",
          "doc",
          "xls",
          "xlsx",
        ];
      },
    },
  },
  watch: {
    value: {
      deep: true,
      handler(param) {
        const _file = cloneDeep(param);
        this.files = [..._file];
      },
    },
  },
  methods: {
    rules(vmodel, label, is_file) {
      if (is_file) {
        return [this.validateRules.requiredFile(vmodel, label)];
      } else {
        return [this.validateRules.required(vmodel, label)];
      }
    },
    handleClick() {
      this.$refs.refInputEl.click();
    },
    async uploadFile(uploads) {
      const { files } = uploads.target;
      if (this.getAvailableLimit > 0) {
        let validLength = this.getMaxLimit - this.files.length;
        if (files.length < validLength) {
          validLength = files.length;
        } else {
          if (files.length > validLength) {
            const ErrorMessage = `Maximum ${this.getMaxLimit} file allowed.`;
            console.log(ErrorMessage);
            ErrorEventBus.$emit("update:error", ErrorMessage);
            // commonStore.setError([{ value: true, message: ErrorMessage }]);
          }
        }
        const validFiles = [];
        const errors = [];
        for (let key = 0; key < validLength; key++) {
          const file = files[key];
          const file_name = files[key].name.split(".").slice(0, -1).join(".");
          const fileType = `${files[key].name.split(".").pop()}`;
          const fileSize = files[key].size / 1024;
          const validType = this.validFileTypes; //['jpeg', 'jpg', 'png', 'gif']
          let isValid = true;
          if (!validType.includes(lowerCase(fileType))) {
            isValid = false;
            errors.push({
              value: true,
              message: `Only ${this.getAllowedFiles} file allowed.`,
            });
          }
          if (fileSize > this.getMaxSize * 1024) {
            isValid = false;
            errors.push({
              value: true,
              message: `"${file_name}" size is too large.`,
            });
          }
          if (isValid) {
            const base64 = await this.convert_base_64(file);
            validFiles.push({
              file: file,
              name: file_name,
              fileType: fileType,
              suffix: fileType,
              base64: base64,
            });
            // validFiles.push(files[key])
          } else {
            if (files.length > validLength) {
              validLength++;
            }
          }
        }
        if (errors.length) {
          for (let i = 0; i < errors.length; i++) {
            ErrorEventBus.$emit("update:error", errors[i].message);
          }
        }
        /*  try {
            Object.keys(files).forEach(async (key) => {
              const file = files[key];
              const file_name = file.name.split(".").slice(0, -1).join(".");
              const fileType = `${file.name.split(".").pop()}`;
  
              // Check if the file type is allowed
              if (this.isFileTypeAllowed(fileType)) {
                const base64 = await this.convert_base_64(file);
                this.files.push({
                  file: file,
                  name: file_name,
                  fileType: fileType,
                  suffix: fileType,
                  base64: base64,
                });
              } else {
                // Show an error or alert for disallowed file types
                // alert(`File type ${fileType} is not allowed.`);
              }
              if (this.files.length >= this.getAvailableLimit) {
                console.log({
                  files: this.files.length,
                  limit: this.getAvailableLimit,
                });
                throw new Error("Upload Limit");
                // return false;
              }
            });
          } catch (error) {
            console.log(error);
          } */
        if (validFiles.length) {
          this.files = [...this.files, ...validFiles];
        }
        this.$emit("update:file", this.files);
        this.emitFile();
        this.$nextTick(() => {
          uploads.target.value = null;
        });
      }
    },
    removeFile(index) {
      this.files.splice(index, 1);
      this.emitFile();
    },
    isFileTypeAllowed(fileType) {
      const allowedFileTypes = this.validFileTypes; //["pdf", "doc", "docx", "xls", "xlsx"];
      return allowedFileTypes.includes(fileType);
    },
    convert_base_64(file) {
      return new Promise((resolve, reject) => {
        try {
          const reader = new FileReader();
          reader.addEventListener(
            "load",
            function () {
              resolve(reader.result);
            },
            false
          );
          reader.readAsDataURL(file);
        } catch (error) {
          reject(error);
        }
      });
    },
    emitFile() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.$emit("change", this.files);
      }, 300);
    },
    getAcceptFile() {
      let file = "";
      this.validFileTypes.forEach((file) => {
        const element = lowerCase(file);
        if (element == "jpeg") {
          file += "image/jpeg, ";
        }
        if (element == "jpg") {
          file += "image/jpg, ";
        }
        if (element == "png") {
          file += "image/png, ";
        }
        if (element == "gif") {
          file += "image/gif, ";
        }
        if (element == "pdf") {
          file += ".pdf, ";
        }
        if (element == "docx") {
          file += ".docx, ";
        }
        if (element == "doc") {
          file += ".doc, ";
        }
        if (element == "xls") {
          file += ".xls, ";
        }
        if (element == "xlsx") {
          file += ".xlsx, ";
        }
      });
      return file;
    },
  },
  computed: {
    getAllowedFiles() {
      const _validFiles = this.validFileTypes;
      return _validFiles.join(", ");
    },
    getTotalFiles() {
      if (isArray(this.files)) {
        return this.files.length;
      }
      return 0;
    },
    getMaxLimit() {
      return this.maxLimit;
    },
    getMaxSize() {
      return Number(this.maxSize);
    },
    getAvailableLimit() {
      return this.maxLimit - this.totalFiles;
    },
  },
};
</script>

<style lang="scss" scoped>
.upload-area {
  border: 2px dashed #e2e5ec;
  background: #f6f6f7;
  cursor: pointer;
  border-radius: 4px;
}
.upload-area.required {
  border-color: #ee685e;
  background: #f8e4e4;
}
.hidden {
  display: none;
}
</style>
