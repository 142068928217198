<template>
  <Dialog :common-dialog="mailDialog" :dialog-width="dialogWidth">
    <template v-slot:title>Upload Customer Signature</template>
    <template v-slot:body>
      <v-container class="px-10" fluid>
        <v-form
          ref="mail_form"
          v-model.trim="formValid"
          lazy-validation
          v-on:submit.stop.prevent="submit()"
        >
          <v-layout class="mt-4">
            <v-flex md6>
              <table class="width-100">
                <tr>
                  <td width="100%" align="left">
                    <label class="font-weight-500 font-size-16 required"
                      >Company Stamp:
                    </label>
                  </td>
                </tr>
                <tr class="signature-pad" id="signature-pad">
                  <td width="100%" align="left" valign="center">
                    <div
                      ref="admin_signature_div"
                      style="width: 100%"
                      class="custom-border-grey-dashed"
                    >
                      <FileTemplate
                        :disabled="formLoading"
                        v-on:file:updated="updateAttachment($event)"
                        allowUpload
                      ></FileTemplate>
                    </div>
                  </td>
                </tr>
              </table>
            </v-flex>
            <v-flex md6>
              <table class="width-100">
                <tr>
                  <td width="100%" align="left">
                    <label class="font-weight-500 font-size-16 required"
                      >Customer Signature:
                    </label>
                  </td>
                </tr>
                <tr class="signature-pad" id="signature-pad">
                  <td width="100%" align="left" valign="center">
                    <div
                      ref="admin_signature_div"
                      style="width: 100%"
                      class="custom-border-grey-dashed"
                    >
                      <canvas ref="admin_signature"></canvas>
                    </div>
                  </td>
                </tr>
              </table>
            </v-flex>
          </v-layout>
        </v-form>
      </v-container>
    </template>
    <template v-slot:action>
      <v-btn
        v-on:click="close_dialog()"
        class="mx-2 custom-grey-border custom-bold-button"
      >
        Close
      </v-btn>

      <v-btn
        v-on:click="submit()"
        :disabled="validSignature"
        class="mx-2 custom-bold-button white--text"
        color="cyan"
      >
        <v-icon left>mdi-send mdi-rotate-315</v-icon>Upload
      </v-btn>
    </template>
  </Dialog>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import Dialog from "@/view/pages/partials/Dialog";
/*   import TinyMCE from "@/view/pages/partials/TinyMCE.vue"; */
import FileTemplate from "@/view/pages/partials/FileTemplate";
import { validateEmail } from "@/core/plugins/validation-mixin";
import { /*  GET, */ POST } from "@/core/services/store/request.module";
import SignaturePad from "signature_pad";
export default {
  mixins: [CommonMixin, ValidationMixin],
  props: {
    mailDialog: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
    parentType: {
      type: String,
      default: null,
    },
    parentTypeId: {
      type: Number,
      default: 0,
    },
    typeId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      formLoading: false,
      emailCCShow: false,
      attachments: [],
      admin_signature: null,
      email: {
        recipients: [],
        cc: [],
        subject: null,
        message: null,
      },
    };
  },
  watch: {
    /* mailDialog(param) {
        if (param) {
          this.init();
        }
      }, */
  },
  methods: {
    close_dialog() {
      this.$emit("close", true);

      this.email = {
        recipients: [],
        cc: [],
        subject: null,
        message: null,
      };
    },

    submit() {
      const _this = this;
      _this.formLoading = true;
      _this.email.attachments = _this.attachments;
      _this.email.admin_signature = _this.admin_signature.toDataURL();

      _this.$store
        .dispatch(POST, {
          url: _this.type + "/" + _this.typeId + "/send-mail",
          data: _this.email,
        })
        .then(() => {
          _this.$emit("success", true);
          _this.close_dialog();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    updateAttachment(param) {
      this.attachments = param;
    },
    validateTagEmail(emails) {
      for (let i = 0; i < emails.length; i++) {
        if (!validateEmail(emails[i])) {
          emails.splice(i, 1);
        }
      }
    },
    removeEmail(email, type) {
      const emails = this.email[type];
      const index = this.lodash.findIndex(emails, (row) => email == row);
      if (index >= 0) {
        this.email[type].splice(index, 1);
      }
    },
    autocompleteEmails() {
      return this.lodash.map(this.recipients, (row) => row.email);
    },
    initSignature() {
      const _this = this;
      _this.$nextTick(() => {
        let ccanvas = _this.$refs["admin_signature"];
        let ccanvasDiv = _this.$refs["admin_signature_div"];
        let cparentDiv = document.getElementById("signature-pad");
        let cparentWidth = cparentDiv.offsetWidth / 2;
        ccanvas.setAttribute("width", cparentWidth);
        ccanvasDiv.setAttribute("style", "width:" + cparentWidth + "px");
        _this.admin_signature = new SignaturePad(ccanvas);
      });
    },
  },
  components: {
    Dialog,
    /*   TinyMCE, */
    FileTemplate,
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 70);
    },
    validSignature() {
      return this.admin_signature ? this.admin_signature.isEmpty() : false;
    },
  },
};
</script>
<style type="text/css" scoped>
.signature-pad {
  width: 50% !important;
}
</style>
