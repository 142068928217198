<template>
  <Dialog :commonDialog="cDialog" :dialogWidth="dialogWidth">
    <template v-slot:title>
      <v-layout>
        <v-flex> Update follow Up </v-flex>
      </v-layout>
    </template>
    <template v-slot:body>
      <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll custom-box-top-inner-shadow"
        style="max-height: 90vh; position: relative"
      >
        <v-form
          ref="followUpForm"
          v-model.trim="formValid"
          lazy-validation
          v-on:submit.stop.prevent="createFollowup"
        >
          <v-container class="py-0">
            <v-row class="py-0">
              <v-col md="6">
                <label class="mt-1 font-weight-600 ml-1 required">Title</label>
                <v-text-field
                  v-model.trim="data.follow_up_title"
                  filled
                  label="Title"
                  solo
                  flat
                  color="cyan"
                  :rules="[
                    validateRules.required(data.follow_up_title, 'Title'),
                  ]"
                  :class="{
                    required: !data.follow_up_title,
                  }"
                ></v-text-field>
              </v-col>
              <v-col md="6">
                <label class="mt-1 font-weight-600 ml-1 required"
                  >Reminder Date</label
                >
                <Datepicker
                  v-model="data.follow_up_date"
                  solo
                  :min-date="todayDate"
                >
                </Datepicker>
              </v-col>
              <v-col md="12">
                <template v-for="(file, index) in files">
                  <div class="d-flex" :key="index">
                    <div class="w-50 pr-3" style="margin-top: -37px">
                      <div class="py-1 ml-1 font-weight-600">Attachment</div>
                      <v-file-input
                        :id="`document-file-${index}`"
                        placeholder="Select File"
                        outlined
                        class="custome-files mt-3"
                        prepend-icon=""
                        prepend-inner-icon="mdi-attachment"
                        hide-details
                        v-model="file.file"
                        v-on:change="updateFile(index, $event)"
                        v-on:click:clear="updateFile(index, $event)"
                      ></v-file-input>
                    </div>
                    <div
                      class="w-34 pr-3"
                      style="margin-top: -37px; margin-left: 11px"
                    >
                      <div class="py-1 ml-1 font-weight-600">File Name</div>
                      <TextInput
                        :id="`document-name-${index}`"
                        v-model="file.name"
                        hide-details
                        placeholder="File Name"
                        :suffix="file.suffix"
                        class="custome-files mt-3"
                      ></TextInput>
                    </div>
                    <div class="w-8 pr-3" style="margin-top: -12px">
                      <v-btn
                        :disabled="files.length < 2"
                        v-on:click="removeFile(index)"
                        color="red lighten-1 white--text"
                        class="mt-3"
                        icon
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </div>
                    <div class="w-8 pr-3" style="margin-top: -12px">
                      <v-btn
                        color="cyan white--text"
                        class="mt-3 ml-2"
                        tile
                        depressed
                        v-on:click="addMore()"
                      >
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </template>
              </v-col>
              <v-col md="12">
                <div class="py-1 ml-1 font-weight-600">Remark</div>
                <v-textarea
                  v-model.trim="data.follow_up_remarks"
                  auto-grow
                  dense
                  filled
                  flat
                  label="Remark"
                  solo
                  color="cyan"
                  hide-details
                  row-height="20"
                  v-on:keypress="(e) => manageLimit(e)"
                ></v-textarea>
              </v-col>
              <v-col md="12" class="text-right"
                >{{
                  data.follow_up_remarks ? data.follow_up_remarks.length : 0
                }}/200</v-col
              >
            </v-row>
          </v-container>
        </v-form>
      </perfect-scrollbar>
    </template>
    <template v-slot:action>
      <div class="text-right custom-border-top py-4 mt-4">
        <v-btn
          class="mx-2 custom-bold-button white--text"
          color="cyan"
          :disabled="!formValid || pageLoading"
          :loading="pageLoading"
          v-on:click="createFollowup"
        >
          Save
        </v-btn>
        <!-- <v-btn
          class="mx-2 custom-bold-button white--text"
          color="cyan"
          :disabled="pageLoading"
          :loading="pageLoading"
          v-on:click="createFollowup('closed')"
        >
          Mark As Closed
        </v-btn> -->
        <v-btn
          :disabled="formLoading"
          class="custom-grey-border custom-bold-button"
          v-on:click="$emit('close-dialog', true)"
        >
          Cancel
        </v-btn>
      </div>
    </template>
  </Dialog>
</template>
<script>
import Dialog from "@/view/pages/partials/Dialog.vue";
import { POST, GET } from "@/core/services/store/request.module";
import CommonMixin from "@/core/plugins/common-mixin";
//import CertificateFileTemplate from "@/view/pages/partials/CertificateFileTemplate.vue";
/* import { ServiceFormEventBus } from "@/core/lib/service.form.lib"; */
import Datepicker from "@/view/pages/partials/Datepicker.vue";
import TextInput from "@/view/pages/TextInput";
import ValidationMixin from "@/core/plugins/validation-mixin";

export default {
  mixins: [CommonMixin, ValidationMixin],
  props: {
    cDialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    e_designation: {
      type: Array,
      default: () => {
        return new Array();
      },
    },

    /* visitAction: {
      required: true,
      type: Boolean,
      default: false,
    }, */
    detail: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    visit: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
  },
  watch: {},
  data: () => {
    return {
      visitNoteActive: true,
      todayDate: null,
      formValid: true,
      data: {
        follow_up: null,
        follow_up_remarks: null,
        follow_up_date: null,
        follow_up_title: null,
      },
      files: [
        {
          file: null,
          name: null,
        },
      ],
      pageLoading: false,
      attachments: [],
    };
  },
  methods: {
    manageLimit(e) {
      if (
        this.data.follow_up_remarks &&
        this.data.follow_up_remarks.length > 199
      ) {
        e.preventDefault();
      }
    },
    createFollowup() {
      const _this = this;

      if (!_this.$refs.followUpForm.validate()) {
        return false;
      }
      _this.pageLoading = true;
      let formData = new FormData();

      formData.append(
        "action",
        this.data.follow_up ? this.data.follow_up : "follow-up"
      );
      if (this.data && this.data.follow_up_remarks) {
        formData.append(
          "remark",
          this.data.follow_up_remarks ? this.data.follow_up_remarks : null
        );
      }
      if (this.data && this.data.follow_up_date) {
        formData.append(
          "date",
          this.data.follow_up_date ? this.data.follow_up_date : null
        );
      }
      if (this.data && this.data.follow_up_title) {
        formData.append(
          "title",
          this.data.follow_up_title ? this.data.follow_up_title : null
        );
      }

      if (this.files && this.files.length) {
        for (let i = 0; i < this.files.length; i++) {
          if (this.files && this.files[i] && this.files[i].file) {
            formData.append(`file[${i}][file]`, this.files[i].file);
            formData.append(`file[${i}][name]`, this.files[i].name);
          }
        }
      }
      /*   let requestTYPE = POST;
      let requestURL = "leave";

      if (leaveId > 0) {
        requestURL = `leave/${leaveId}`;
      } */
      _this.$store
        .dispatch(POST, {
          url:
            "job/" +
            _this.detail.id +
            "/visit/" +
            _this.visit.id +
            "/followup/" +
            _this.visit.followup[0].id,
          data: formData,
        })
        .then(() => {
          _this.$emit("success-massage", true);
          _this.$emit("close-dialog", true);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },

    getVisit(id) {
      const _this = this;
      let requestUrl = "job/" + _this.detail.id + "/visit/" + id;
      _this.$store
        .dispatch(GET, { url: requestUrl })
        .then(({ data }) => {
          _this.visitDetail = data;
          // _this.visitNotes = data.notes;
          _this.visitDetailDialog = true;
          _this.visitDeleteRequestURL = requestUrl;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    updateFile(index, file) {
      if (file && file.name) {
        this.files[index].name = file.name.split(".").slice(0, -1).join(".");
        this.files[index].suffix = `.${file.name.split(".").pop()}`;
      } else {
        this.files[index].name = null;
        this.files[index].suffix = null;
      }
    },
    addMore() {
      this.files.push({
        file: null,
        name: null,
      });
    },

    removeFile(index) {
      this.files.splice(index, 1);
      if (this.files.length < 1) {
        this.addMore();
      }
    },

    closeNote() {
      this.formValid = true;
      this.visitNote = null;
      this.visitNoteDocument = new Array();
      this.visitNoteActive = false;
    },
  },
  components: {
    Dialog,
    Datepicker,
    TextInput,
  },

  created() {
    this.todayDate = new Date().toISOString();
    // console.log({todayDate: this.todayDate})
  },

  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 50);
    },
  },
};
</script>
